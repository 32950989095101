import DialogActionWall from 'pages/components/dialogActionWall';
import PrivacyPolicyText from '../modules/privacy/privacyPolicy';

export interface IPrivacyData {
  key: string;
  description: string;
}

interface IPrivacyPolicy {
  openModal: boolean;
  setOpenModal: (event: boolean) => void;
}

const PrivacyPolicy = ({ openModal, setOpenModal }: IPrivacyPolicy) => {
  return (
    <DialogActionWall
      open={openModal}
      closeIconButton
      onClose={() => setOpenModal(false)}
      title={''}
      dialogTitleSxProps={{
        textAlign: 'center'
      }}
      dialogActionsSxProps={{
        justifyContent: 'center'
      }}
      dialogPaperSxProps={{
        maxWidth: 900
      }}
    >
      <PrivacyPolicyText />
    </DialogActionWall>
  );
};

export default PrivacyPolicy;
