import DialogActionWall from 'pages/components/dialogActionWall';
import TermsOfUseText from '../modules/privacy/termsOfUse';

interface ITermsOfUse {
    openModal: boolean;
    setOpenModal: (event: boolean) => void;
}

const TermsOfUse = ({ openModal, setOpenModal }: ITermsOfUse) => {
    return (
        <DialogActionWall
            open={openModal}
            closeIconButton
            onClose={() => setOpenModal(false)}
            title={''}
            dialogTitleSxProps={{
                textAlign: 'center'
            }}
            dialogActionsSxProps={{
                justifyContent: 'center'
            }}
            dialogPaperSxProps={{
                maxWidth: 900
            }}
        >
            <TermsOfUseText />
        </DialogActionWall >
    );
};

export default TermsOfUse;
